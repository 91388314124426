<template>
  <!-- NAME[epic=绩效] 拜访统计 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="80px"
    >
      <el-form-item prop="depart_id">
        <el-select
          v-model="form.depart_id"
          placeholder="部门"
          style="width: 120px"
        >
          <el-option
            v-for="(item, index) in departmentList"
            :key="index"
            :label="item.text"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="staff_id">
        <el-select
          v-model="form.staff_id"
          clearable
          style="width: 140px"
          placeholder="业务员"
        >
          <el-option
            v-for="(i, idx) in staffList"
            :key="idx"
            :value="i.user_id"
            :label="i.user_name"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="" prop="">
        <sale-area-select-tree
          ref="saleAreaSelectTree"
          @set-sale-select-id="setSaleSelectId"
        ></sale-area-select-tree>
      </el-form-item>
      <el-form-item label="" prop="channel_id">
        <el-select
          v-model="form.channel_id"
          clearable
          placeholder="请选择渠道"
          style="width: 160px"
        >
          <el-option
            v-for="item in channelList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="class_id">
        <el-select
          v-model="form.class_id"
          clearable
          placeholder="请选择客户等级"
          style="width: 160px"
        >
          <el-option
            v-for="item in clientLevelList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="line_id">
        <el-select
          v-model="form.line_id"
          placeholder="请选择线路"
          clearable
          style="width: 130px"
          filterable
        >
          <el-option
            v-for="item in lineSelect"
            :key="item.id"
            :label="item.line_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search
          ref="clientSearch"
          :popper-class="'select-idx selectDC'"
          @select-val-alldata="selectCustId"
          @select-val-input="selectCustId"
        ></client-search>
      </el-form-item>

      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 240px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '位置'">
            <span :style="{ color: row.abnormal == 0 ? '#606266' : 'red' }">
              {{ row[item.prop] }}
            </span>
          </div>
          <div v-else>
            {{ row[item.prop] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import { peopleList, departmentList, visitList } from '@/api/performance'
  import SaleAreaSelectTree from '@/baseComponents/saleAreaSelectTree'
  import { getClientChannelList, getClientLevelList } from '@/api/setPrice'
  import { clientLineList } from '@/api/ClientDetail'
  import { downloadFile, postAction } from '@/api/Employee'
  import { day_30 } from '@/utils/Time'
  import clientSearch from '@/baseComponents/clientSearch'
  export default {
    name: 'Summary2',
    components: { SaleAreaSelectTree, clientSearch },
    data() {
      return {
        departmentList: [],
        staffList: [],
        channelList: [],
        clientLevelList: [],
        lineSelect: [],
        form: {
          start_time: '', //开始时间 如2020-12-22
          end_time: '', //结束时间 如2020-12-22
          pageSize: 10,
          pageNo: 1,
        },
        time: [],
        list: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        columns: [
          {
            order: 1,
            label: '业务员',
            prop: 'user_name',
            width: '90',
          },
          {
            order: 2,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 3,
            label: '编码',
            prop: 'cust_pinyin',
            width: '',
          },
          {
            order: 4,
            label: '进店时间',
            prop: 'join_time',
            width: '',
          },
          {
            order: 5,
            label: '停留时间',
            prop: 'stay_time',
            width: '',
          },
          {
            order: 6,
            label: '位置',
            prop: 'abnormal_text',
            width: '',
          },
          {
            order: 7,
            label: '线路',
            prop: 'line_name',
            width: '',
          },
          {
            order: 8,
            label: '访销金额',
            prop: 'yd_total_amount',
            width: '',
          },
          {
            order: 9,
            label: '访销退货',
            prop: 'yd_refund_goods_amount',
            width: '',
          },
          {
            order: 9,
            label: '车销金额',
            prop: 'cx_total_amount',
            width: '',
          },
          {
            order: 9,
            label: '车销退货',
            prop: 'cx_refund_goods_amount',
            width: '',
          },
        ],
        loading: false,
      }
    },
    computed: {},
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      // 处理时间
      console.log('day_30()', day_30())
      this.form.start_time = day_30()[0]
      this.form.end_time = day_30()[1]
      this.time = day_30()
      // 部门下拉
      departmentList().then((res) => {
        this.departmentList = res.data.list
      })
      peopleList({ pageSize: -1, is_close: 0 }).then((res) => {
        console.log(res)
        this.staffList = res.data
      })
      // 获取渠道下拉
      getClientChannelList().then((res) => {
        if (res.code == 200) this.channelList = res.data
      })
      // 获取客户等级下拉
      getClientLevelList().then((res) => {
        if (res.code == 200) this.clientLevelList = res.data
      })
      // 线路下拉
      clientLineList().then((res) => {
        if (res.code == 200) this.lineSelect = res.data
      })
      this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await postAction(
          '/taskAdmin/path/visit-list-by-cust',
          this.form
        )
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },

      // 子组件传入 设置销售区域id
      setSaleSelectId(val) {
        this.form.area_id = val
      },
      selectCustId(val) {
        if (val.id) {
          this.form.cust_id = val.id
          this.form.cust_name = ''
        } else {
          this.form.cust_id = ''
          this.form.cust_name = val
        }
      },
      handleExport() {
        downloadFile(
          '/taskAdmin/path/visit-list-by-cust-export',
          '拜访统计(按客户).xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>
